<template>
  <div id="apps">
   <div class="tuikuan clear">
       <div class="heads clear">
         <img src="../assets/timg.jpeg"/>
         <div class="legt">
            <p>商品已签收</p>
            <p>申通快递已经签收，签收申通快递已经签收，签收申通快递已经签收，签收</p>
         </div>
       </div>
      <div class="ulli clear">
        <img src="../assets/th2.png" class="img"/>
        <div class="conten">
          <p>我要退货退款</p>
          <p>已收到货，需要退还已收到的货物</p>
        </div>
       <img src="../assets/jt2.png" class="yu"/>
      </div>
       <div class="ulli clear">
        <img src="../assets/th1.png" class="img"/>
        <div class="conten">
          <p>我要退款(无需退货)</p>
          <p>未收到货，或与商家协商之后申请</p>
        </div>
       <img src="../assets/jt2.png" class="yu"/>
      </div>
   </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  components:{
  },
   data () {
      return {

      }
  },
  methods:{
  }
}
</script>
<style>
  .clear{clear:both;overflow: hidden;}
  *{margin:0;padding:0;}
  li{list-style-type: none;}
  body{background-color: rgba(248, 248, 248, 100);height: 100vh;}
  .tuikuan .heads{width:100%;background: #fff;}
  .tuikuan .heads img{width:15%;display: inline-block;float: left;margin:3%;}
  .tuikuan .heads .legt{float: left;margin-top:3%;width:78%;}
  .tuikuan .heads .legt{font-size:13px;display: block;}
  .tuikuan .heads .legt p:nth-child(2){color:#AEAEAE;margin-top:3%;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;}
  .ulli{width: 94%;margin:3% auto 0 auto;border-radius:6px;background:#fff;padding:6% 0;}
 .ulli .img{display: inline-block;float: left;width: 5%;margin:1% 2% 0 5%;}
 .ulli .conten{float: left;width: 70%;}
 .ulli .yu{width:5%;float: right;margin-right:4%;margin-top:5%;}
 .ulli .conten p{font-size:13px;display: block;}
  .ulli .conten p:nth-child(2){font-size:12px;color:#AEAEAE;margin-top:3%;}
  </style>
